<template>
  <div>
    <b-modal
      size="xl"
      v-model="workerFilesModalShow"
      @change="(val) => $emit('update:worker-files-modal-show', val)"
      id="modal-tall"
    >
      <!-- <b-icon-arrows-angle-contract
        v-if="showingPdf"
        class="cursor-pointer"
        @click="showingPdf = false"
      /> -->
      <b-card class="text-center">
        <b-row>
          <div :key="file.id" v-for="file in workerFiles" style="margin:10px;">
            <span class="file">
              <b-icon-trash-fill
                class="cursor-pointer icon-trash"
                @click="deleteFile(file)"
              />
              <pdf
                :src="file.name"
                style="
                  height: 200px;
                  overflow-y: scroll;
                "
              />
              <a :href="file.name" target="_blank">
                <!-- <img :src="fileImage(file.name)" width="200" ><br> -->
                {{ fileName(file.name) }}
              </a>
            </span>
          </div>
        </b-row>
      </b-card>
      <template #modal-footer="{}">
        <label>Сканы
          <input
            type="file"
            id="files"
            ref="files"
            multiple
            @change="handleFilesUpload()"
          >
        </label>
        <b-button
          size="lg"
          variant="btn btn-primary"
          @click="submitFiles()"
        >Загрузить</b-button>
        <!-- <b-button size="lg" variant="btn btn-primary" @click="closeModal">
          Закрыть
        </b-button> -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormTextarea,
  BCard,
  BCardText,
  BRow,
  BIconTrashFill,
} from 'bootstrap-vue'
import Vue from 'vue'
import { ref, watch } from "@vue/composition-api";
import pdf from 'vue-pdf'
import store from '@/store'

export default {
  components: {
    BButton,
    BModal,
    BFormTextarea,
    BCard,
    BCardText,
    BRow,
    BIconTrashFill,
    pdf,
  },

  model: {
    prop: 'workerFilesModalShow',
    event: 'update:worker-files-modal-show',
  },

  props: {
    workerFilesModalShow: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    workerFiles() {
      return this.$store.state.info.workerFiles
    },
  },

  data() {
    return {
      files: '',
      userId: '',
    }
  },

  methods: {
    fileName(file) {
      const fileName = file.split('/').pop()
      return decodeURI(fileName)
    },
    fileImage(file) {
      const extension = file.split('.').pop()

      if (extension == 'png' || extension == 'jpg' || extension == 'jpeg') {
        return file
      }

      switch (extension) {
        case 'docx':
          return 'file_docx.png'
        case 'pdf':
          return 'file_pdf.png'
        default:
          return 'file_file.png'
      }
    },
    handleFilesUpload() {
      this.files = this.$refs.files.files
      console.log(this.files)
    },

    closeModal() {},

    getFiles() {},

    deleteFile(file) {
      store
        .dispatch('info/deleteFile', {
          id: file.id,
          user_id: this.userId,
        })
        .then(() => {
          Vue.$toast.success('Файл удален', { timeout: 2000 })
          // emit('update:child-report-modal-show', false)
        })
        .catch(() => {
          Vue.$toast.error('Не удалось удалить файл', { timeout: 2000 })
        })
    },

    submitFiles() {
      store
        .dispatch('info/submitFiles', {
          files: this.files,
          user_id: this.userId,
        })
        .then(() => {
          Vue.$toast.success('Файлы загружены', { timeout: 2000 })
          // emit('update:child-report-modal-show', false)
        })
        .catch(() => {
          Vue.$toast.error('Не удалось загрузить файлы', { timeout: 2000 })
        })
    },
  },
}
</script>

<style scoped>
.file {
  position: relative;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
.icon-trash {
  position: absolute;
  z-index: 1;
  top: 0;
  right: -90px;
  color: red;
}
</style>
