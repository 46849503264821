<template>
  <b-row>
    <b-col cols="12" class="mb-1">
      <b-button @click="openSidebar" variant="primary"> Добавить </b-button>
      <list-workers-add-new ref="listWorkerAddNew"></list-workers-add-new>
      <worker-files
        :worker-files-modal-show.sync="workerFilesModalShow"
        :userId.sync="userId"
      />
    </b-col>
    <b-col cols="2" class="mb-1">
      <b-form-group
        label="Фильтр"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            @input="filterData"
            placeholder="Поиск"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Очистить
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="4" class="mb-1">
      <b-input-group-append>
        <b-button
          :variant="bDayActive ? 'primary' : 'secondary '"
          @click="bDays()"
        >
          Ближайшие дни рождения
        </b-button>
      </b-input-group-append>
    </b-col>

    <b-col cols="12">
      <b-table
        :fields="fields"
        :items="items"
        responsive
        show-empty
        empty-text="Нет материалов"
        @row-selected="onRowSelected"
      >
        <template #cell(actions)="row">
          <b-icon-pencil-fill
            class="cursor-pointer"
            @click="editSidebar(row.item)"
          >
          </b-icon-pencil-fill>

          <b-icon-file-earmark-fill
            class="cursor-pointer"
            @click="showFiles(row.item)"
          >
          </b-icon-file-earmark-fill>
        </template>
      </b-table>
    </b-col>
    <!--    <b-col cols="12">-->
    <!--      <b-pagination-->
    <!--          v-model="currentPage"-->
    <!--          class="mt-1"-->
    <!--          align="right"-->
    <!--          :total-rows="totalCount"-->
    <!--          :per-page="limit"-->
    <!--          @change="pageChanged"-->
    <!--      />-->
    <!--    </b-col>-->
  </b-row>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BPagination,
  BRow,
  BTable,
  BIconPencilFill,
  BIconFileEarmarkFill,
} from "bootstrap-vue";
import ListWorkersAddNew from "@/views/workers/ListWorkersAddNew";
import WorkerFiles from "@/views/workers/WorkerFiles.vue";

export default {
  name: "ListWorkers",
  components: {
    ListWorkersAddNew,
    WorkerFiles,
    BButton,
    BLink,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BPagination,
    BRow,
    BCol,
    BIconPencilFill,
    BIconFileEarmarkFill,
  },
  data() {
    return {
      workerFilesModalShow: false,
      userId: "",
      fields: [
        {
          key: "id",
          label: "Номер",
        },
        {
          key: "full_name",
          label: "ФИО Сотрудника",
        },
        {
          key: "snil",
          label: "СНИЛС",
        },
        {
          key: "passport",
          label: "Паспорт",
        },
        {
          key: "birth_date",
          label: "Дата рождения",
        },
        {
          key: "phone_number",
          label: "Телефон",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "groups",
          label: "Должность",
        },
        {
          key: "work_status_translate",
          label: "Статус",
        },
        {
          key: "actions",
          label: "Действия",
        },
      ],
      filter: "",
      bDayActive: false,
      selected: [],
      currentPage: 1,
      limit: 10,
    };
  },
  computed: {
    items() {
      return this.$store.state.info.workersList;
    },
  },
  created() {
    this.fetchData(1);
  },
  methods: {
    editSidebar(worker) {
      this.$store.commit("info/setWorkerToEdit", worker);
      this.$refs.listWorkerAddNew.isAddWorkersSidebarActive = true;
    },
    openSidebar() {
      this.$refs.listWorkerAddNew.isAddWorkersSidebarActive = true;
    },
    fetchData(page) {
      // const params = {
      //   limit: this.limit,
      //   offset: page === 1 ? 0 : (page * this.limit) - this.limit,
      // }
      const params = {};
      if (this.filter !== "") {
        params.search = this.filter;
      } else {
        delete params.search;
      }
      this.$store.dispatch("info/getWorkers", params);
    },
    filterData() {
      this.fetchData(1);
    },
    pageChanged(page) {
      this.fetchData(page);
    },
    onRowSelected(items) {
      this.$emit("items-selected", items);
    },

    showFiles(worker) {
      let params = { id: worker.id };
      this.userId = worker.id;
      this.$store.dispatch("info/getFiles", params);
      this.workerFilesModalShow = true;
    },
    
    bDays() {
      if (!this.bDayActive) this.$store.dispatch("info/getWorkerBDays");
      else this.fetchData(1);

      this.bDayActive = !this.bDayActive;
    },
  },
};
</script>

<style lang="scss">
.table-custom-active {
  background-color: #161d31;
}
</style>
